.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

.logo {
  
  background: transparent url("D:\Sitios_React\GbaseleccionRRHH\frontend\src\Assets\Img\Login.svg") no-repeat;
  background-size: 11rem;
  display: block;
}